@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Questrial&family=Roboto+Condensed&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #f3f6f7;
  margin: 0;
  font-family: 'Questrial', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
